import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom"

import App from './components/App'
import Locale from "./components/utilities/Locale"

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Locale>
        <App />
      </Locale>
    </BrowserRouter>
  </React.StrictMode>
)