import { Container, Card } from "react-bootstrap"
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import IMILogo from '../../resources/media/logos/imi-logo.svg'
import UMFLogo from '../../resources/media/logos/umf-logo.png'
import GULogo from '../../resources/media/logos/gu-logo.png'

import '../../css/App.css'
import '../../css/content/Imprint.css'

function Imprint() {
    return (
        <motion.div
            initial={{opacity: 0}} 
            animate={{opacity: 1}} 
            exit={{opacity: 0}}>
            <Container>
                <div className='center imprintLogos'>
                    <a href='https://imi-frankfurt.de' target='_blank' rel='noreferrer'><img src={IMILogo} alt='IMI Logo' className='imiLogo' /></a>
                    <a href='https://unimedizin-ffm.de' target='_blank' rel='noreferrer'><img src={UMFLogo} alt='UMF Logo' className='instLogo' /></a>
                    <a href='https://uni-frankfurt.de' target='_blank' rel='noreferrer'><img src={GULogo} alt='GU Logo' className='instLogo' /></a>
                </div>
                <Card style={{ width: '100%', border: '0'}}>
                    <Card.Body>
                        <Card.Title className='contentHeadline'><FormattedMessage id="imprint.headline" /></Card.Title>
                        <Card.Text><FormattedMessage id="imprint.description" values={{p: (...chunks) => <p>{chunks}</p>, br: <br />, h5: (...heading) => <h5>{heading}</h5>, h4: (heading4) => <h4>{heading4}</h4>}} /></Card.Text>
                        <Card.Subtitle className='contentHeadline'><FormattedMessage id="imprint.haftungHeadline" /></Card.Subtitle>
                        <Card.Text><FormattedMessage id="imprint.haftungDescription" values={{p: (...chunks) => <p>{chunks}</p>, br: <br />, h5: (...heading) => <h5>{heading}</h5>, h4: (heading4) => <h4>{heading4}</h4>}} /></Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </motion.div>
    )
  }
  
  export default Imprint