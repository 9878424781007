import React, {useState} from 'react'
import {IntlProvider} from 'react-intl'

import German from '../../resources/lang/de.json'
import English from '../../resources/lang/en.json'

export const Context = React.createContext()

const local = navigator.language

let lang
if (local === 'en') {
   lang = English
} else if (local === 'de') {
    lang = German
} else {
    lang = English
}

const Locale = (props) => {
   const [locale, setLocale] = useState(local)
   const [messages, setMessages] = useState(lang)
   function selectLanguage(lang) {
       const newLocale = lang
       setLocale(newLocale)
       if (newLocale === 'en') {
           setMessages(English)
       } else if (newLocale === 'de') {
            setMessages(German)
        } else {
            setMessages(English)
        }
    }

   return (
       <Context.Provider value = {{locale, selectLanguage}}>
           <IntlProvider messages={messages} locale={locale}>
               {props.children}
           </IntlProvider>
       </Context.Provider>
   )
}

export default Locale