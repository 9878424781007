import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'

import ProSkiveLogo from '../resources/media/logos/proskive-logo.png'
import DownButton from '../resources/media/icons/down-button.png'
import IMILogo from '../resources/media/logos/imi-logo.svg'
import UMFLogo from '../resources/media/logos/umf-logo.png'
import GULogo from '../resources/media/logos/gu-logo.png'

import '../css/Title.css'
import '../css/App.css'

function Title() {
    return(
        <motion.div className="title"
            initial={{opacity: 1}}
            exit={{opacity: 0}}>
            <Container>
                <div  className='center'>
                    <img src={ProSkiveLogo} alt='ProSkive Logo' className='proskiveLogo' />
                    <div>
                        <a href='https://imi-frankfurt.de' target='_blank' rel='noreferrer'><img src={IMILogo} alt='IMI Logo' className='imiLogo' /></a>
                        <a href='https://kgu.de' target='_blank' rel='noreferrer'><img src={UMFLogo} alt='UMF Logo' className='instLogo' /></a>
                        <a href='https://uni-frankfurt.de' target='_blank' rel='noreferrer'><img src={GULogo} alt='GU Logo' className='instLogo' /></a>
                    </div>
                </div>
                <div className='downButton bounce'>
                    <NavLink to='/about'><img src={DownButton} alt='Down Button' /></NavLink>
                </div>
            </Container>
        </motion.div>
    )
}

export default Title